import { setPlayerTeam } from 'redux/slices';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

export function NextPlayersInQueue() {
  const players = useSelector((state) => state.players);
  const playersPerTeam = useSelector((state) => state.game.playersPerTeam);
  const dispatch = useDispatch();
  const playersQueueIsEmpty = players.filter((p) => !p.isPlaying && p.checkedIn).length === 0;

  const teamAPlayersIsEmpty = !players.filter((p) => p.teamId === 1).length;
  const teamBPlayersIsEmpty = !players.filter((p) => p.teamId === 2).length;
  const bothTeamsHavePlayers = !teamAPlayersIsEmpty && !teamBPlayersIsEmpty;

  const nextPlayers = players.filter((p) => p.teamId === 4);

  const getNextPlayers = () => {
    const playersWaiting = players.filter((p) => p.teamId === 0 && p.checkedIn);

    const candidates = playersWaiting
      .sort((a, b) => b.waitingFor - a.waitingFor)
      .reduce((acc, player) => {
        if (acc.length === 0 || acc[acc.length - 1][0].waitingFor !== player.waitingFor) {
          acc.push([player]);
        } else {
          acc[acc.length - 1].push(player);
        }
        return acc;
      }, [])
      .flatMap((group) => group.sort(() => 0.5 - Math.random()));

    candidates.slice(0, playersPerTeam).forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 4 }));
    });
  };

  useEffect(() => {
    if (!nextPlayers.length && bothTeamsHavePlayers) getNextPlayers();
  }, [nextPlayers.length, bothTeamsHavePlayers]);

  return (
    nextPlayers.length === 0 && playersQueueIsEmpty
      ? (
        <Typography variant="h5" textAlign="center">
          Não há jogadores em espera
        </Typography>
      )
      : (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 4,
          }}
        >
          <Typography variant="h5" textAlign="center">
            {nextPlayers.length > 0 && 'Próxima:'}
            {' '}
            {nextPlayers.map((player, index) => (
              `${player.playerName}${index === nextPlayers.length - 1 ? '' : ', '}`
            ))}
          </Typography>
        </Box>
      )
  );
}
