import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ScrollToTop } from 'components';
import {
  NotFound,
  Players,
  Scoreboard,
  Draft,
  UpdateStore,
  Youtube,
} from 'pages';

export function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/scoreboard" element={<Scoreboard />} />
        <Route path="/" element={<Scoreboard />} />
        <Route path="/players" element={<Players />} />
        <Route path="/players-ranking" element={<Players ranking />} />
        <Route path="/players-admin" element={<Players isAdmin />} />
        <Route path="/draft" element={<Draft />} />
        <Route path="/update-store" element={<UpdateStore />} />
        <Route path="/youtube" element={<Youtube />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
