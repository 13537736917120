import { Box, Divider, Button } from '@mui/material';
import {
  AddPlayer, PlayersQueue, TopBar, PlayersOffline, SelectNumberOfPlayersPerTeam, CheckoutAllPlayers,
} from 'components';
import {
  removeAllPlayersFromTeams,
  removeAllPlayersFromTeamBoard,
} from 'redux/slices';
import { useDispatch } from 'react-redux';

export function Players({ ranking = false, isAdmin = false }) {
  const dispatch = useDispatch();
  const handleRemoveAllPlayersFromTeams = () => {
    dispatch(removeAllPlayersFromTeams());
    dispatch(removeAllPlayersFromTeamBoard());
  };
  return (
    <>
      <TopBar />
      <Box mt={4} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: { xs: 2, md: 6 },
        }}
      >
        <AddPlayer />
        <SelectNumberOfPlayersPerTeam />
      </Box>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        gap: 2,
        mt: 4,
      }}
      >
        <PlayersQueue />
        <Divider orientation="vertical" variant="middle" flexItem />
        <PlayersOffline ranking={ranking} isAdmin={isAdmin} />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          my: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            p: 2,
          }}
        >
          <CheckoutAllPlayers />
          <Button sx={{ mt: 2, width: '100%' }} variant="contained" color="secondary" onClick={handleRemoveAllPlayersFromTeams}>Resetar Times</Button>
        </Box>
      </Box>
    </>
  );
}
