/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { Button, Box } from '@mui/material';
import { setPlayerTeam, addPlayerToTeam, resetAllTeamsStats } from 'redux/slices';
import { useSelector, useDispatch } from 'react-redux';

export function BalanceTeamsBtn() {
  const players = useSelector((state) => state.players);
  const dispatch = useDispatch();

  const balanceTeams = () => {
    const currentTeamA = players.filter((player) => player.teamId === 1);
    const currentTeamB = players.filter((player) => player.teamId === 2);

    const bothTeams = [...currentTeamA, ...currentTeamB];

    const teamA = [];
    const teamB = [];

    const sortedPlayers = [...bothTeams].sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses));

    sortedPlayers.forEach((player, index) => {
      if (index % 2 === 0) {
        teamA.push(player);
      } else {
        teamB.push(player);
      }
    });

    const teamAValue = teamA.reduce((acc, curr) => acc + (curr.wins - curr.losses), 0);
    const teamBValue = teamB.reduce((acc, curr) => acc + (curr.wins - curr.losses), 0);

    const isDifferenceTooBig = teamAValue > teamBValue
      ? teamAValue - teamBValue > 10
      : teamBValue - teamAValue > 10;

    if (isDifferenceTooBig) {
      const bestPlayer = teamAValue > teamBValue
        ? teamA.sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses))[0]
        : teamB.sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses))[0];

      const worstPlayer = teamAValue > teamBValue
        ? teamB.sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses))[0]
        : teamA.sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses))[0];

      // remove best player from its team
      teamAValue > teamBValue
        ? teamA.splice(teamA.indexOf(bestPlayer), 1)
        : teamB.splice(teamB.indexOf(bestPlayer), 1);

      // remove worst player from its team
      teamAValue > teamBValue
        ? teamB.splice(teamB.indexOf(worstPlayer), 1)
        : teamA.splice(teamA.indexOf(worstPlayer), 1);

      // add best player to the other team
      teamAValue > teamBValue
        ? teamB.push(bestPlayer)
        : teamA.push(bestPlayer);

      // add worst player to the other team
      teamAValue > teamBValue
        ? teamA.push(worstPlayer)
        : teamB.push(worstPlayer);
    }

    teamA.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 1 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 1 }));
    });

    teamB.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 2 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 2 }));
    });
    dispatch(resetAllTeamsStats());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
      }}
    >
      <Button variant="contained" onClick={balanceTeams} size="small">Balancear times</Button>
    </Box>
  );
}
