/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import { Button } from '@mui/material';
import { setPlayerTeam, addPlayerToTeam } from 'redux/slices';
import { useSelector, useDispatch } from 'react-redux';

export function GenerateTwoTeamsBtn() {
  const players = useSelector((state) => state.players);
  const playersPerTeam = useSelector((state) => state.game.playersPerTeam);
  const dispatch = useDispatch();

  const generateTwoTeams = () => {
    const checkedInPlayers = players.filter((player) => player.checkedIn === true);

    const teamA = [];
    const teamB = [];

    const sortedPlayers = [...checkedInPlayers].sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses));

    sortedPlayers.forEach((player) => {
      if (teamA.length < teamB.length || (teamA.length < playersPerTeam && teamB.length === playersPerTeam)) {
        teamA.push(player);
      } else if (teamB.length < playersPerTeam) {
        teamB.push(player);
      }
    });

    teamA.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 1 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 1 }));
    });
    teamB.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 2 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 2 }));
    });
  };

  return <Button variant="contained" onClick={generateTwoTeams}>Gerar 2 times</Button>;
}
