/* eslint-disable max-len */
import {
  useEffect, useRef, useState,
} from 'react';
import {
  Box, Paper, Button, Typography,
} from '@mui/material';
import {
  addScoreToBoard, cancelScoreToBoard, stopGameCounter, resetScore, setPointsHistory,
} from 'redux/slices';
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';
import * as S from 'components/styles';

export function Score() {
  const lastButtonPressTimeRef = useRef({});
  const [spinTeamA, setSpinTeamA] = useState(false);
  const [spinTeamB, setSpinTeamB] = useState(false);

  const {
    gameStarted, scoreA, scoreB, pointsHistory,
  } = useSelector((state) => state.game);
  const teams = useSelector((state) => state.teams);
  const dispatch = useDispatch();

  const pointsHistoryRef = useRef(pointsHistory);

  const teamA = teams.find((t) => t.id === 1);
  const teamB = teams.find((t) => t.id === 2);
  const teamAWinLastGame = teamA?.winLastGame;
  const teamBWinLastGame = teamB?.winLastGame;
  const teamAScoreIsZero = scoreA === 0;
  const teamBScoreIsZero = scoreB === 0;

  const addScore = (team) => {
    if (gameStarted) {
      dispatch(setPointsHistory([...pointsHistory, team]));
      pointsHistoryRef.current = [...pointsHistoryRef.current, team];
      dispatch(addScoreToBoard(team));
      if (team === 'A') {
        const isFirstScore = scoreA === 0;
        const lastScoreIsFromTeamA = pointsHistoryRef.current[pointsHistoryRef.current.length - 2] === 'A';
        console.log(pointsHistoryRef.current);
        console.log(lastScoreIsFromTeamA);
        if (isFirstScore && teamAWinLastGame && teamBScoreIsZero) return null;
        if (isFirstScore && !teamAWinLastGame) return null;
        if (!lastScoreIsFromTeamA) {
          setSpinTeamA(true);
          return setTimeout(() => setSpinTeamA(false), 5000);
        }
      }
      if (team === 'B') {
        const isFirstScore = scoreB === 0;
        const lastScoreIsFromTeamB = pointsHistoryRef.current[pointsHistoryRef.current.length - 2] === 'B';
        console.log(pointsHistoryRef.current);
        console.log(lastScoreIsFromTeamB);
        if (isFirstScore && teamBWinLastGame && teamAScoreIsZero) return null;
        if (isFirstScore && !teamBWinLastGame) return null;
        if (isFirstScore && teamBWinLastGame && !teamAScoreIsZero) {
          setSpinTeamB(true);
          return setTimeout(() => setSpinTeamB(false), 5000);
        }
        if (!lastScoreIsFromTeamB) {
          setSpinTeamB(true);
          return setTimeout(() => setSpinTeamB(false), 5000);
        }
      }
    }
    return null;
  };

  const cancelScore = (team) => {
    if (pointsHistory.length > 0 || pointsHistoryRef.current.length > 0) {
      const newPointsHistory = [...pointsHistory];
      newPointsHistory.pop();
      pointsHistoryRef.current.pop();
      dispatch(setPointsHistory(newPointsHistory));
      dispatch(cancelScoreToBoard(team));
    }
  };

  const fontSize = { xs: '10rem', md: '35rem' };

  const handleKeyDown = (e) => {
    console.log(e);
    if (e.key === 'a' || e.key === 'A' || e.key === 'q' || e.key === 'z') {
      addScore('A');
    }
    if (e.key === 'ç' || e.key === 'Ç' || e.key === 'p' || e.key === '.') {
      addScore('B');
    }
    if (e.key === 's' || e.key === 'S' || e.key === 'w' || e.key === 'x') {
      cancelScore('A');
    }
    if (e.key === 'l' || e.key === 'L' || e.key === 'o' || e.key === ',') {
      cancelScore('B');
    }
    if (e.key === 'Enter') {
      dispatch(resetScore());
    }
  };

  useEffect(() => {
    if (gameStarted) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      dispatch(stopGameCounter());
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [gameStarted]);

  const updateGamepadStatus = () => {
    const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
    const currentTime = Date.now();
    Array.from(gamepads).forEach((gamepad) => {
      if (gamepad) {
        gamepad.buttons.forEach((button, index) => {
          if (button.pressed) {
            // Check if the button was pressed more than 1 second ago
            if (!lastButtonPressTimeRef.current[index] || currentTime - lastButtonPressTimeRef.current[index] > 1000) {
              if (index === 1) { console.log('score A'); addScore('A'); }
              if (index === 2) addScore('B');
              if (index === 4) cancelScore('A');
              if (index === 5) cancelScore('B');
              lastButtonPressTimeRef.current[index] = currentTime; // Update the last pressed time
            }
          }
        });
      }
    });
    requestAnimationFrame(updateGamepadStatus);
  };

  useEffect(() => {
    const handleGamepadConnected = () => {
      requestAnimationFrame(updateGamepadStatus);
    };

    const handleGamepadDisconnected = () => {
      // Logic for gamepad disconnection
    };

    window.addEventListener('gamepadconnected', handleGamepadConnected);
    window.addEventListener('gamepaddisconnected', handleGamepadDisconnected);

    return () => {
      window.removeEventListener('gamepadconnected', handleGamepadConnected);
      window.removeEventListener('gamepaddisconnected', handleGamepadDisconnected);
    };
  }, []);

  useEffect(() => {
    let newSocket = null;
    newSocket = io('https://api.pandlr.com', { transports: ['websocket'] });
    newSocket.on('connect', () => {
      newSocket.emit('join', { room: 'volleyball-club-1000' });
    });
    newSocket.on('scoreA', () => {
      addScore('A');
    });
    newSocket.on('scoreB', () => {
      addScore('B');
    });
    newSocket.on('cancelA', () => {
      cancelScore('A');
    });
    newSocket.on('cancelB', () => {
      cancelScore('B');
    });

    return () => {
      if (newSocket) {
        newSocket.emit('leave', { room: 'volleyball-club-1000' });
        newSocket.disconnect();
      }
    };
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: '#34495e', color: '#fff', minHeight: '100vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center',
      }}
    >
      <Box
        mb={3}
        ml="-3rem"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="50%"
      >
        <Box>
          {spinTeamA
            ? (
              <S.SpinningTypography
                onClick={() => addScore('A')}
                variant="h1"
                component="h1"
                fontSize={fontSize}
                textAlign="center"
              >
                {scoreA}
              </S.SpinningTypography>
            )
            : (
              <Typography
                onClick={() => addScore('A')}
                variant="h1"
                component="h1"
                fontSize={fontSize}
                textAlign="center"
              >
                {scoreA}
              </Typography>
            )}
        </Box>
        <Box>
          <Button disabled={scoreA === 0 || !gameStarted} size="large" style={{ height: 50 }} variant="contained" onClick={() => cancelScore('A')}>Cancelar ponto</Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto', // Adjust this to manage the space the "X" takes
        }}
      >
        <Typography fontSize={{ xs: '4rem', md: '12rem' }} variant="h1" component="h1" textAlign="center">
          X
        </Typography>
      </Box>
      <Box
        mb={3}
        ml="-3rem"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="50%"
      >
        <Box>
          {spinTeamB
            ? (
              <S.SpinningTypography
                onClick={() => addScore('B')}
                variant="h1"
                component="h1"
                fontSize={fontSize}
                textAlign="center"
              >
                {scoreB}
              </S.SpinningTypography>
            )
            : (
              <Typography
                onClick={() => addScore('B')}
                variant="h1"
                component="h1"
                fontSize={fontSize}
                textAlign="center"
              >
                {scoreB}
              </Typography>
            )}
        </Box>
        <Box>
          <Button disabled={scoreB === 0 || !gameStarted} size="large" style={{ height: 50 }} variant="contained" onClick={() => cancelScore('B')}>Cancelar ponto</Button>
        </Box>
      </Box>
    </Paper>
  );
}
